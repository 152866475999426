<template>
  <b-overlay :show="loading" rounded>
    <header v-if="myGudang">
      <h5>
        <strong>Lokasi Gudang: {{ this.myGudang.nama_gudang }}</strong>
      </h5>
    </header>
    <b-row>
      <b-col md="2" sm="12">
            <p><strong>Tanggal</strong></p>
            <p>{{ so.tanggal }}</p>
          </b-col>
      <!-- <b-col md="2" sm="12">
            <p><strong>No</strong></p>
            <p>{{ so.no }}</p>
          </b-col> -->
          <b-col md="5" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ so.keterangan ? so.keterangan : "-" }}</p>
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 0">
            <p><strong>Status</strong></p>
            <b-badge variant="light-danger" size="sm"
              >Belum Selesai</b-badge
            >
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 1">
            <p><strong>Status</strong></p>
            <b-badge variant="light-success" size="sm"
              >Selesai</b-badge
            >
          </b-col>

      <!-- Input Stock Opname -->
      <b-col>
        <b-card style="overflow-y: auto; max-height: 500px">
          <b-table small bordered striped hover responsive :fields="fields" :items="items">
            <template #cell(no)="row">
              <div class="text-center">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(jumlahreal)="{ item }">
              <b-form-input type="number" v-model="item.jumlah" />
            </template>
            <template #cell(so)="{ item }">
              {{ item.stok_opname_id }}
            </template>
            <template #cell(selisih)="{ item }">
              {{ item.jumlah - item.stok_real }}
            </template>
          </b-table>
        </b-card>
      </b-col>
      <!-- / -->
    </b-row>
    <footer class="d-flex justify-content-end">
      <b-button v-if="so.status == 0" variant="primary" @click.prevent="save">Simpan</b-button>
      <!-- <b-button variant="info" @click.prevent="resetform">Reset</b-button> -->
    </footer>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BCard,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BTable,
  BFormInput,
  BLink,
    BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BCard,
    BFormGroup,
    BFormSelect,
    BOverlay,
    vSelect,
    BTable,
    BFormInput,
    BLink,
    BBadge,
  },
  data() {
    return {
      loading: false,
      selectedBarang: null,
      barangs: [],
      penyimpanan_id: [],
      jumlah: 0,
      stok: 0,
      stok_opname_id: 0,
      status: 1,
      so: {
        tanggal: null,
        no: null,
        keterangan: null,
        status: null,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      fields: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "barang.nama",
          label: "Nama Barang",
          sortable: true
        },
        {
          key: "blok.blok",
          label: "Blok",
          sortable: true
        },
        {
          key: "palet.palet",
          label: "palet",
        },
        {
          key: "rak.rak",
          label: "rak",
        },
        {
          key: "laci.laci",
          label: "laci",
        },
        {
          key: "jumlah",
          label: "Stok",
        },
        {
          key: "barang.satuan.satuan",
          label: "satuan",
        },
        {
          key: "stok_real",
          label: "Hasil Stok Opname",
        },
        {
          key: "selisih",
          label: "selisih barang",
        }
      ],
    };
  },
  mounted() {
    // this.getData()
    this.getDataSo()
    this.getDataSoRincian()
    if (this.$route.params.id) {
      this.stok_opname_id = this.$route.params.id;
    }
  },
  methods: {
    resetform() {
      this.item.jumlahreal = 0;
      this.jumlah = 0;
    },
    async save() {
      if (this.id) {
        this.id = this.id
      }
      let payloads = []
      this.penyimpanan_id.map(item => {
        payloads.push({
          stok_opname_id: this.$route.params.id,
          id_penyimpanan: item.id,
          stok: item.jumlah,
          jumlah: item.jumlahreal
        })
      })
      let payload1 = {
        status: this.status
      }
      if (this.$route.params.id) {
            payload1.id = this.$route.params.id;
          }
      try {
        this.loading = true
        await this.$store.dispatch('tokostokopname/saveSo', payloads)
        await this.$store.dispatch('tokostokopname/save', [payload1])
        this.loading = false

        this.displaySuccess({
          message: 'Stok Opname berhasil disimpan!'
        })
        this.getData();
        setTimeout(() => {
            this.loading = false;
            this.$router.push({ name: 'cabang-stock-opname-rincian', params: { id: this.$route.params.id } });
          }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getData() {
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        // start: currentPage,
        // length: this.perPage,
        gudang_id: this.myGudang.id,
      };

      const stocks = await this.$store.dispatch("tokopenyimpanan/getData", payload)
      let items = JSON.parse(JSON.stringify(this.$store.state.tokopenyimpanan.datas));
      let items_total = this.$store.state.tokopenyimpanan.totals;
      this.penyimpanan_id = items;
      // this.items = items;
      this.totalRows = items_total;
      // this.totalRows = JSON.parse( JSON.stringify(stocks) ).length
    },
    getDataSoRincian() {
      // if (this.user.level.id == 0) {
      this.$store
        .dispatch("tokostokopname/getDataSo", { stok_opname_id: this.$route.params.id })
        .then(() => {
          this.items = this.$store.state.tokostokopname.datas2;
          // this.totalRows = this.items.length;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
      // }
    },
    async getDataSo() {
      const so = await this.$store
        .dispatch("tokostokopname/getDataById", this.$route.params.id);
          this.so = so;
    }
  },
  watch: {
    selectedBarang(barang) {
      if (barang) {
        this.barangs.push({
          id: barang.value,
          name: barang.text,
          jumlah: 0,
          isFinalStock: false,
        });
      }
    },
  },
};
</script>
